import {Controller} from "stimulus"
import $ from 'jquery';
import {checkSessionVar, setSessionVar} from "./sessionStorage_controller";



export default class extends Controller {

    connect() {

    }

    previewImg(e){
        let archivos = e.target.files;
        let preview = $('#'+e.target.dataset.preview);
        preview[0].src = URL.createObjectURL(archivos[0]);
    }
}