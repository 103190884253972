import consumer from "./consumer"

function set_read(id) {
  let result;
  $.ajax({
    url: location.origin+`/notifications/set_read`,
    data: {
      id: id
    },
    type: 'POST',
    success: function (data) {
      result = data;
    }
  });

  return result;
}

document.addEventListener('turbo:load', () => {
  const user_element = document.getElementById('user_notification_id')
  if(user_element){
    const user_id = Number(user_element.getAttribute('data-user-id'))
    const notificationContainer = document.getElementById('notification_container')
    consumer.subscriptions.create({channel: "NotificationsChannel", user_id: user_id}, {
      connected() {
        if( Notification.permission === 'default' ) {
          Notification.requestPermission(function (permission) {
            // callback
            if (permission === 'granted') {
              toastr.success('Ahora podra recibir notificaciones de escritorio.');
            }
          });
        }
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (location.pathname.includes('rooms/') && location.pathname.includes('/show')){
          let set_valur_read = set_read(data.id);
        }else{
          let notification_id = Math.floor(Math.random() * (99999999999999999999 - 1)) + 1;
          var opciones = {
            icon: "favicon.png", /* (opcional) aún no implementado */
            body: data.text, /* (opcional) si se omite el título será el cuerpo */
            tag: notification_id /* (opcional) Nunca habrá dos notificaciones con la misma etiqueta, así que cuando se muestre se cerrarán las otras que tengan la misma etiqueta */
          }
// Creamos la notificación
          var notification = new window.Notification(data.from, opciones);

          //ACCIONES SOBRE LA NOTIFICACION
          notification.onclick = function() {
            notification.close();
            window.parent.focus();
            window.focus();
            location.href = location.origin+data.url;
          }

          //AGREGAR NOTIFICACION A LA BARRA DE INICIO Y REPRODUCIR SONIDO DE MENSAJE
          notificationContainer.innerHTML = data.notification+ notificationContainer.innerHTML;
          let notification_count = document.getElementById('notification_count');
          notification_count.innerHTML = parseInt(notification_count.innerText) + 1;
          const tone_message = document.getElementById('tone_message');
          tone_message.play();
        }

      }
    })
  }

})
;
