import { Controller } from "stimulus"
import $ from 'jquery';

import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es.js"
require("flatpickr/dist/themes/airbnb.css")

export default class extends Controller {

    connect() {

            flatpickr(".datepicker", {
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "Y-m-d",
                minDate: "today",
                locale: Spanish,
                disableMobile: "true"
            })

            flatpickr(".timepicker", {
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i",
                time_24hr: false,
                locale: Spanish,
                disableMobile: "true"
            })
    }
}