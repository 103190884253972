import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar, checkSessionVar, checkSessionVarWithData} from './sessionStorage_controller'
import {get_popup} from './popup_controller'
import {set_locale} from './localize_controller'


import {preferences} from './preferences_controller'
import {catalog} from './catalogs_controller'
import Swal from 'sweetalert2/dist/sweetalert2'
import {blockBody, unblockBody} from './blockUI_controller'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import 'slick-carousel/slick/slick.min'
import {cancel_to_shopping_cart, create_array_for_product} from "./b2b_controller";
import {session} from "@hotwired/turbo";

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim ());
}

let aleatori_behavior = () => {
    // var myArray = ['backInLeft', 'backInRight', 'backInUp', 'bounceIn', 'bounceInLeft', 'bounceInRight', 'bounceInUp', 'fadeInLeftBig', 'fadeInRightBig', 'fadeInUpBig'];
    var myArray = ['backInUp', 'bounceIn', 'bounceInUp'];
    var rand = Math.floor (Math.random () * myArray.length);
    return myArray[rand];
}


require ("select2/dist/css/select2")
require ("select2-bootstrap-theme/dist/select2-bootstrap")


let prev = 0;
let next = 0;

let products_for_map = () => new Promise ((resolve, reject) => {

    $.ajax ({
        url: `${location.origin}/${sessionStorage.getItem ('locale')}/admin/product_types/json`,
        data: {},
        type: 'GET',
        success: function (data) {
            if (data.rows.length > 0) {
                let types = ''
                data.rows.map ((element) => {
                    if (parseInt (element.count) > 0) {
                        types += `<li style="cursor: pointer; user-select: none;">
                                <label for="input_map_${element.id}" class="d-flex justify-content-start", style="gap:0 25px;">
                                <input id="input_map_${element.id}" type="checkbox" value="${element.id}" class="checkbox_map_filter">
                                <span class="d-flex justify-content-start" style="gap:0 5px"><span class="icon-li">${element.icon}</span> ${element.name}</span></li></label>`;
                    }
                });

                resolve (types);
            }
        }
    })


})

let save_data_pre_reservation = (obj) => new Promise ((resolve, reject) => {

    $.ajax ({
        url: location.origin + `/${sessionStorage.getItem ('locale')}/admin/products/get_product_data`,
        data: {
            category: obj.dataset.categoryid
        },
        type: 'get',
        success: async (data) => {
            let category = await setSessionVar ('category', data.data.code, 'add', false);
            let category_text = await setSessionVar ('text_category', data.data.name, 'add', false);
            let day_delay = await setSessionVar ('day_delay_rent', data.data.reservation_time, 'add', false);
            let day_min = await setSessionVar ('day_min_rent', data.data.min_length_reservation, 'add', false);
            let day_max = await setSessionVar ('day_max_rent', data.data.max_length_reservation, 'add', false);
            let description = await setSessionVar ('category_description', data.data.description, 'add', false);
            let product = await setSessionVar ('product_id', obj.dataset.id, 'add', false);
            let success = 0;
            let fail = 0;

            [category, category_text, day_delay, day_min, day_max, description, product].map (elem => {
                if (elem.success) {
                    success = success + 1;
                } else {
                    fail = fail + 1;
                }
            })

            resolve ({
                success: true,
                success_count: success,
                fail_count: fail

            })
        }

    })


})

let getSeller = async (text) => {
    let p = await preferences (text, 'api');
    setSessionVar ('seller', p.value, 'add', false)
}

let staticSection = (context) => new Promise ((resolve, reject) => {
    let section_conatiner = $ ('#section_container');
    let footer_container = $ ('#footer_section');
    let html = '<div data-controller="word wowjs">';
    let footer = '';
    let section_show_html = $('#sections_show');
    let sections_show_in = '';
    $.ajax ({
        url: `/${sessionStorage.getItem ('locale')}/admin/sections.json`,
        data: {format: "json"},
        type: "get",
        success: function (sections) {
            sections.map ((section) => {
                if (section_conatiner[0] !== undefined) {
                    if (section.destination === 'body') {
                        if (section.show_in_bar) {
                            sections_show_in += `<a id="to_section_${section.id}" class="nav-link" href="javascript:;" data-action="setValue#saltarA" data-target="#section_${section.id}">
                                                    ${section.name_in_bar}
                                                  </a>`
                        }

                        let img = section.thumbnails_url || section.image_url;
                        html += context.tpl_section (section.id, section.access_url, img, section.helpertext, section.name, section.descriptions.body, section.backgroud, section.typesection);
                    }
                }
                if (footer_container[0] !== undefined) {
                    if (section.destination === 'footer') {
                        footer += context.tpl_section_footer (section.id, section.access_url, section.name, section.typesection);
                    }
                }
            });

            if (section_show_html.length > 0) {
                section_show_html[0].innerHTML = sections_show_in;
            }

            if (section_conatiner[0] !== undefined) {
                html += '</div>';
                section_conatiner[0].innerHTML = html;
            }

            if (footer_container[0] !== undefined) {
                footer_container.html (footer);
            }



            resolve ({
                next: true
            })

        }
    })
});
let staticPage = (context) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: `/${sessionStorage.getItem ('locale')}/admin/pages.json`,
        data: {format: "json"},
        type: "get",
        success: function (pages) {
            let html = '';
            let count_page = {};

            pages.map ((page) => {

                if (page.show) {
                    let url = '';
                    let section_body = $ ('#section_' + page.section.id);

                    if(count_page.hasOwnProperty('section_' + page.section.id)){
                        count_page['section_' + page.section.id] += 1;
                    }else{
                        count_page['section_' + page.section.id] = 1
                    }


                    if (page.isExternaUrl) {
                        url = page.access_url;
                    } else {
                        if (page.access_url === null || page.access_url === "" || page.access_url === undefined) {
                            url = 'javascript:;';
                        } else {
                            url = location.origin + `/${sessionStorage.getItem ('locale')}/pages/ver/` + page.access_url;
                        }
                    }

                    if (page.section.destination === 'body') {
                        let image = page.thumbnails_url || page.image_url;
                        let icon = '';
                        let description = '';
                        if (page.icon !== null) {
                            if (!page.icon.isEmpty ()) {
                                if (page.icon.includes ('fa')) {
                                    icon = `fa ${page.icon}`;
                                } else {
                                    icon = page.icon;
                                }
                            }
                        }

                        if (page.short_description !== null ) {
                            description = page.short_description;
                        }

                        if(page.section.typesection === 'presentation-time-line'){
                            description = page.contents.body
                        }

                        if (section_body !== undefined) {
                            section_body.append (context.tpl_page (page.id, url, page.isExternaUrl, page.name, description, page.section.typesection, image, icon, count_page['section_' + page.section.id]));
                        }
                    }

                    if (page.section.destination === 'footer') {
                        if (section_body !== undefined) {
                            section_body.append (context.tpl_page_footer (page.id, url, page.isExternaUrl, page.name));
                        }
                    }

                }
            })


            resolve ({
                next: true
            });
        }
    })
})


export default class extends Controller {

    initialize () {
        set_locale ().then (
            locale => {
                const btn_assistant_line = $ ('#btn_assistant_line');
                if (location.pathname.includes ('help_me')) {
                    btn_assistant_line[0].style.display = 'none';
                }
                if (sessionStorage.hasOwnProperty ('need_clean')) {
                    if (sessionStorage.getItem ('need_clean') === "true" && sessionStorage.getItem ('inPayProccess') === "true" && location.pathname.includes ("/payment/return")) {
                        setSessionVar ('', '', 'clear', false);
                    }
                }

                //Disabled input
                $ ('#date_collect').attr ('disabled', true);
                $ ('#time_collect').attr ('disabled', true);
                $ ('#date_delivery').attr ('disabled', true);
                $ ('#time_delivery').attr ('disabled', true);

                getSeller ('c2e_api_key');

                const context = this;
                staticSection (context).then (
                    result => {
                        if (result.next) {
                            staticPage (context).then (
                                page_result => {
                                    if (page_result.next) {
                                        // $('.slick_catalog').slick({
                                        //     speed: 300,
                                        //     slidesToShow: 4,
                                        //     slidesToScroll: 2,
                                        //     rows: 1,
                                        //     infinite: true,
                                        //     autoplay: true,
                                        //     autoplaySpeed: 3000,
                                        //     responsive: [
                                        //         {
                                        //             breakpoint: 1800,
                                        //             settings: {
                                        //                 slidesToShow: 4,
                                        //                 slidesToScroll: 2,
                                        //             }
                                        //         },
                                        //         {
                                        //             breakpoint: 1280,
                                        //             settings: {
                                        //                 slidesToShow: 3,
                                        //                 slidesToScroll: 3,
                                        //             }
                                        //         },
                                        //         {
                                        //             breakpoint: 900,
                                        //             settings: {
                                        //                 slidesToShow: 2,
                                        //                 slidesToScroll: 2,
                                        //             }
                                        //         },
                                        //         {
                                        //             breakpoint: 700,
                                        //             settings: {
                                        //                 slidesToShow: 1,
                                        //                 slidesToScroll: 1,
                                        //                 rows: 1,
                                        //             }
                                        //         },
                                        //
                                        //         // You can unslick at a given breakpoint now by adding:
                                        //         // settings: "unslick"
                                        //         // instead of a settings object
                                        //     ]
                                        // })
                                        $ ('.slick_notice').slick ({
                                            infinite: false,
                                            speed: 300,
                                            slidesToShow: 4,
                                            slidesToScroll: 1,
                                            rows: 1,
                                            responsive: [
                                                {
                                                    breakpoint: 1300,
                                                    settings: {
                                                        slidesToShow: 3,
                                                        slidesToScroll: 1,
                                                        infinite: true,
                                                    }
                                                },
                                                {
                                                    breakpoint: 1028,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 800,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },

                                                // You can unslick at a given breakpoint now by adding:
                                                // settings: "unslick"
                                                // instead of a settings object
                                            ]
                                        });
                                        $ ('.slick-three-column').slick ({
                                            infinite: false,
                                            speed: 300,
                                            slidesToShow: 3,
                                            slidesToScroll: 1,
                                            rows: 1,
                                            responsive: [
                                                {
                                                    breakpoint: 1028,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 700,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },

                                                // You can unslick at a given breakpoint now by adding:
                                                // settings: "unslick"
                                                // instead of a settings object
                                            ]
                                        });
                                        $ ('.slick-five-column').slick ({
                                            infinite: false,
                                            speed: 300,
                                            slidesToShow: 4,
                                            slidesToScroll: 1,
                                            rows: 1,
                                            responsive: [
                                                {
                                                    breakpoint: 1028,
                                                    settings: {
                                                        slidesToShow: 3,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 700,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },

                                                // You can unslick at a given breakpoint now by adding:
                                                // settings: "unslick"
                                                // instead of a settings object
                                            ]
                                        })

                                        unblockBody ();

                                    }
                                }
                            )
                        }
                    }
                )

            })
        $ ('#reviews-wrapper').slick ({
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        rows: 1,
                    }
                }, {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        rows: 1,
                    }
                }

                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        })
    }

    connect () {
    }

    prev (e) {

        let element = e.target.dataset.target;
        if (prev < 0) {
            next -= (window.innerWidth - 20);
            prev += (window.innerWidth - 10);
            $ ('#' + element)[0].style.left = `${prev}px`;
        }
    }

    next (e) {
        next += (window.innerWidth - 20);
        prev -= (window.innerWidth - 10);
        let element = e.target.dataset.target;
        $ ('#' + element)[0].style.left = `${-next}px`;
    }

    setSessionKey (e) {
        setSessionVar (e.target.dataset.name, e.target.value, 'add', e.target.dataset.isdate, false).then (
            data => {
                create_array_for_product (e.target.dataset.name, data.value, 'add');
                if (e.target.dataset.hasOwnProperty ('samedate')) {
                    if (e.target.dataset.samedate === 'true') {
                        let add_date = parseInt (e.target.dataset.adddate) || 1;
                        setSessionVar (e.target.dataset.datename, e.target.value, 'add', e.target.dataset.isdate, false, e.target.dataset.samedate || false, add_date).then (
                            same_date_data => {
                                create_array_for_product (e.target.dataset.datename, same_date_data.value, 'add');
                            })
                    }
                }

            }
        );
    }


    tpl_section (id, url, picture, helpertext, name, description, background, type) {



        let html = `<div class="row no-print ${background} wow bounceInUp">`;
        switch (type) {
            case 'presentation_with_image_left':
                html += `
                <div class="col-md-12 section">
                  <div class="presentation-with-image-left">`;
                if (picture !== undefined) {
                    html += `<div class="image-container">
                           <div class="image-divider-mobile"></div>
                           <img src="${picture}" alt="${name}">
                        </div>`;
                }
                html += `
                    <div class="info-container">
                      <div class="helper-text">
                       ${helpertext}
                      </div>
                      <div class="title-text last-word-change">
                        ${name}
                      </div>
                      <div class="divider"></div>
                      <div class="description-text">
                        ${description}
                      </div>
                       <div class="button_action">
                                    <button onclick="location.href = '/${sessionStorage.getItem ('locale')}/sections/ver/${url}'"> ${I18n.t ('global.main.read_more')}</button>
                        </div>
                    </div>

                    <div class="page-wrapper">
                      <div class="divider-vertical"></div>
                      <div class="">
                        <ul id="section_${id}" class="pages"></ul>
                      </div>
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation_with_image_5_columns':
                html += `
                <div class="col-md-12">
                  <div class="presentation-with-pages-by-column">
                    <div class="presentation-title">
                      <div class="presentation-text last-word-change"> ${name}</div>
                      <div class="divider"></div>
                      <div class="presentation-helper"> ${helpertext}</div>
                    </div>
                   
                    <div id="section_${id}" class="presentation-body slick-five-column">
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation_with_image_3_columns':
                html += `
                 <div class="col-md-12 ">
                  <div class="presentation_with_image_3_columns">
                    <div class="presentation-title">
                      <div class="presentation-text last-word-change" > ${name}</div>
                      <div class="divider"></div>
                    </div>
                    
                    <div id="section_${id}" class="presentation-body slick-three-column">
            
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation-whit-image-right':
                html += `<span id="section_${id}_textname" class="ocultar">${name}</span>`;
                if (window.innerWidth <= 640) {
                    html += `
                <div class="col-md-12">
                  <div class="presentation-whit-image-right">
                    <div class="page-container">
                      <div class="page-header last-word-zoom" data-target="text_section_${id}" data-source="section_${id}_textname">${name}</div>
                      <div class="divider"></div>
                       <div class="image-container">
                      <div id="text_section_${id}" class="text-extra"></div>
                         <div class="image-wrapper">
                              <img src="${picture}" alt="${name}">
                            </div>
                        </div>
                      <div class="page-helper-text">${helpertext}</div>
                      <div class="page-wrapper">
                        <ul id="section_${id}" class="page-list">
            
                        </ul>
                      </div> 
                      <div class="page-buttons">
                        <button onclick="location.href =location.origin+'/${sessionStorage.getItem ('locale')}/sections/ver/${url}'">${I18n.t ('global.main.read_more')}</button>
                      </div>
                    </div>
                   
                  </div>
                </div>
                `;
                } else {
                    html += `
                <div class="col-md-12">
                  <div class="presentation-whit-image-right">
                    <div class="page-container">
                      <div class="page-header last-word-zoom" data-target="text_section_${id}" data-source="section_${id}_textname">${name}</div>
                      <div class="divider"></div>
                      <div class="page-helper-text">${helpertext}</div>
                      <div class="page-wrapper">
                        <ul id="section_${id}" class="page-list">
            
                        </ul>
                      </div> 
                      <div class="page-buttons">
                       <button onclick="location.href =location.origin+'/${sessionStorage.getItem ('locale')}/sections/ver/${url}'">${I18n.t ('global.main.read_more')}</button>
                      </div>
                    </div>
                    <div class="image-container">
                      <div id="text_section_${id}" class="text-extra"></div>
                         <div class="image-wrapper">
                              <img src="${picture}" alt="${name}">
                            </div>
                        </div>
                  </div>
                </div>
                `;
                }
                break;
            case 'presentation_with_image_2_columns':
                html += `
                 <div class="col-md-12 section">
                  <div class="presentation_with_image_2_columns">
                    <div id="section_${id}" class="page-container">
            
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation-paralax-with-3-columns':
                html += `
                 <div class="col-md-12 ">
                  <div class="presentation-paralax-with-3-columns">
                    <div class="backgroud-paralax" style="background-image: url('${picture}');">
                     
                      <div class="presentation-wrapper">
                        <div class="presentation-helper-text">${helpertext}</div>
                        <div class="presentation-header">
                          ${name}
                        </div>
                        <div class="presentation-description">
                          <div class="description-text">
                            ${description}
                          </div>
                        </div>
                        <div id="section_${id}" class="presentation-pages"></div>
                      </div>
                    </div>          
                  </div>       
                </div>
                `;
                break;
            case 'presentation-with-notice':
                html += `
                   <div class="col-md-12 section">
                    <div class="presentation-with-notice">
                      <div class="notice-container">
                      <div class="notice-header">
                       <div class="notice-title last-word-change">${name}</div>
                        <div class="notice-divider"></div>
                        </div>
                        <div class="notice-body">
                         <div id="section_${id}" class="notice-list slick_notice"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                `;
                break;
            case 'presentation-comercial-client':
                html += `
                        <div class="container-fluid">
                          <div class="presentation-comercial-client">
                              <div class="presentation-title col-12 col-md-12">
                                <div class="presentation-text last-word-change" data-controller="word">
                                  ${name}
                                </div>
                                <div class="mb-3">
                                ${helpertext}
                                </div>
                                <div class="divider"></div>
                              </div>
                              <div id="section_${id}" class="presentation-body row align-items-end"></div>
                            </div>
                       
                        
                        </div>
                `;
                break;
            case 'presentation-with-map':
                html += `
                        <div class="container-fluid">
                          <div class="presentation-with-map" id="data_map" data-icon="/map/pin.png">
                              <div class="presentation-title col-12 col-md-12">
                                  <div class="presentation-text last-word-change">${name}</div>
                                  <div class="description">${helpertext}</div>
                                  <div class="divider"></div>
                                </div>
                              <div id="overlay_map_load" class="overlay_map_load"></div>
                                    <div class="for_map" data-controller="map">
                                      <div class="action_map_buttons">
                                      <div class="box_action">
                                         <button id="button_filter_map_${id}" class="button_map" type="button" data-action="setValue#active_desactive_box_map" data-target="box_options_${id}">
                                           <span class="fa fa-filter" data-target="box_options_${id}"></span>
                                         </button>
                                         <div id="box_options_${id}" class="box_options">
                                           <ul id="map_li_options${id}">
                                            
                                            </ul>
                                            <button type="button" class="btn_filter_map" data-action="map#refreshMap" data-close="box_options_${id}" data-target="checkbox_map_filter">
                                              Filtrar
                                            </button>
                                         </div>
                                        </div>
                                        
                                       </div>
                                      <div id="map" ></div>
                                    </div>
                                </div>
                            </div>
                       
                        
                        </div>
                `;

                products_for_map ().then (
                    types => {
                        let li_opt = $ (`#map_li_options${id}`);
                        if (types.isEmpty ()) {
                            $ (`#button_filter_map_${id}`).addClass ('ocultar');
                        } else {
                            li_opt.html (types);
                        }

                    }
                )
                break;
            case 'marquee':
                  html += `
                     <div class="container-fluid mt-3 mb-5 pb-5" data-controller="marquee" id="section_${id}">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="marquee" data-target='marquee_state' data-action="mouseenter->marquee#marquee mouseleave->marquee#marquee">
                                <div id='marquee_state' class="content">
                        
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  `;
                break;
            case 'catalog':
                html += `
                     <div class="container-fluid wow bounceInUp">
                        <div class="row mt-2">
                          <div class="col-12" >
                            <div class="catalog" id="section_${id}">
                              <h2>${helpertext}</h2>
                              <div id="catalogo_container" class="catalog-body slick_catalog" data-controller="catalogs" data-show="true">
                    
                              </div>
                              <div id="more_catalog_result" class="more-results">
                                <button id="btn_more_result_catalog" type="button" data-controller="catalogs" data-action="catalogs#get_more_data">${I18n.t('global.main.view_more') }</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  `;
                break;
            case 'presentation-time-line':
                html+= `
                <div class="presentation-time-line">
                  <div class=" mt-5 mb-5 pt-5">
                    <div class="row">
                      <div class="col-md-8">
                        <h2>${name}</h2>
                        <ul class="timeline" id="section_${id}">
                          
                        </ul>
                      </div>
                      <div class="col-md-4 page-image-container" >
                         <img src="${picture}" alt="${name}">
                      </div>
                    </div>
                  </div>
                </div>
                `
                break;
            case 'faqs':
                html += `
                 <div class="container-fluid">
                  <div class="row my-5">
                      <div class="col-12 ">
                        <div id="faqs"  class="presentation-faq">
                          <h2>${name}</h2>
                          <div class="accordion mt-3" id="section_${id}" data-controller="faqs"></div>
                        </div>
                      </div>
                  </div>
                </div>
                `;
                    break;
        }

        html += `</div>`;

        return html;
    }

    tpl_section_footer (id, url, name, type) {
        let html = ``;

        switch (type) {
            case 'footer':
                html += `
                   <div class="footer-section">
                    <div class="section-title">
                      ${name}
                    </div>
                    <div id="section_${id}" class="section-page-wrapper">
                    </div>
                  </div>
                `;
                break;
        }

        return html;
    }

    tpl_page (id, url, isExternal, name, description, type, picture, icon, index) {
        let des = description || ' ';
        let html = ``;

        switch (type) {
            case 'presentation_with_image_left':
                html = `
                    <li class="page-section">
                    <div class="page-icon">
                        <span class="${icon}"></span>
                    </div>
                    <div class="page-container">
                        <div class="page-title">
                            ${name}
                        </div>
                        <div class="divider"></div>
                        <div class="page-body">
                           ${des}
                        </div>
                    </div>
                </li>`;
                break;
            case 'presentation_with_image_5_columns':
                html = ` <div class="">
                    <div class="page-card">
                      <div class="page-slider">
                        <div class="page-card-image">
                          <img src="${picture}" alt="${name}">
                        </div>
                        <div class="page-card-body">
                            <div class="page-title">
                                ${name}
                            </div>
                            <div class="page-divider"></div>
                            <div class="page-helper">
                                ${description}
                            </div>
                            <div class="page-buttons">
                                 <button onclick="location.href = '${url}'">${I18n.t ('global.main.read_more')}</button>
                            </div>
                        </div>
                        </div>
                   
                  </div>
                </div>
                </div>`;
                break;
            case 'presentation_with_image_3_columns':
                html = ` 
                 <div class="">
                 <div class="page-card">
                                  <div class="page-slider">
                                    <div class="page-card-image">
                                      <img src="${picture}" alt="${name}" onclick="location.href = '${url}'">
                                    </div>
                                    <div class="page-title">
                                      ${name}
                                    </div>
                                    <div class="page-divider"></div>
                                  </div>
                                </div>
                </div>
                 `;
                break;
            case 'presentation-whit-image-right':
                html = ` <li>
                    <div class="page-icon">
                      <span class="${icon}"></span>
                    </div>
                    <div class="page-container">
                      <div class="page-title">${name}</div>
                      <div class="divider-page"></div>
                    </div>
                  </li>`;
                break;
            case 'presentation_with_image_2_columns':
                let text = des.slice (0, 250);
                html = ` <div class="page">
                <div class="page-image-container">
                <div class="overlay"></div>
                  <img src="${picture}" alt="${name}">
                </div>
                <div class="page-body-container">
                  <div class="body-header">${name}</div>
                  <div class="body-text">${text}...</div>
                  <div class="body-button">
                    <button onclick="location.href = '${url}'">${I18n.t ('global.main.read_more')}</button>
                  </div>
                </div>
              </div>`;
                break;
            case 'presentation-paralax-with-3-columns':
                html = `
                  <div class="page">
                    <button onclick="location.href = '${url}'">${name}</button>
                  </div>
                `;
                break;
            case 'presentation-with-notice':
                html += `
                    <div class="">
                     <div class="notice">
                        <div class="n-picture">
                          <img src="${picture}" alt="${name}">
                        </div>
                        <div class="n-divider"></div>
                        <div class="n-wrapper">
                        <div class="n-title">
                          ${name}
                        </div>               
                        <div class="n-body">
                          ${des}
                        </div>
                        <div class="n-footer">
                          <button type="button" onclick="location.href = '${url}'">${I18n.t ('global.main.read_more')}</button>
                        </div>   
                        </div>
                                     
                      </div>
                    </div>
                 
                `;
                break;
            case 'presentation-comercial-client':
                html += `
                               <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                <div class="card-comercial">
                                  <div class="image-container">
                                    <img src="${picture}" alt="${name}">
                                  </div>
                                  <div class="image-text d-flex justify-content-center">
                    
                                    <div class="divider"></div>
                                  </div>
                                </div>
                              </div>
                                 `;
                break;
            case 'presentation-time-line':
                html += `
                        <li data-step="${index}">
                            <a target="_blank" href="javscript:;">${name}</a>
                            <a href="javascript:;" class="float-right"></a>
                            <p>${description}</p>
                        </li>
`
                break;
        }


        return html;
    }


    tpl_page_footer (id, url, isExternal = false, name) {
        let html = '';
        if (isExternal) {
            html = ` <div class="page">
                          <a href="${url}" target="_blank">${name}</a>
                        </div>
            `;
        } else {
            html = ` <div class="page">
                          <a href="${url}" >${name}</a>
                        </div>
            `;
        }
        return html
    }

    print (e) {
        set_locale ().then (
            locale => {
                location.href = location.origin + `/${locale.locale}/histories/${e.target.dataset.history}.pdf`

            }
        )
    }

    cancel_reservation () {
        get_popup ('cancel_rent').then (
            cancel_rent => {
                Swal.fire ({
                    title: cancel_rent.title || 'Cancelar reserva',
                    text: cancel_rent.description || "¿Usted desea cancelar su reserva?",
                    icon: cancel_rent.icon || 'question',
                    showCancelButton: true,
                    confirmButtonText: I18n.t ('global.popup.cancel_rent_confirm'),
                    cancelButtonText: I18n.t ('global.popup.cancel_rent_cancel'),
                    reverseButtons: true,
                    allowOutsideClick: false,
                    // backdrop: `#354052`,
                    backdrop: `rgba(255, 255, 255, 1)`,
                    customClass: {
                        confirmButton: 'btn btn-secondary mt-2',
                        cancelButton: 'btn btn-success margin-right-15 mt-2'
                    },
                    buttonsStyling: false
                })
                    .then (async (result) => {
                        blockBody ();
                        if (result.isConfirmed) {

                            cancel_to_shopping_cart().then();
                        }
                        // if (result.isDismissed) {
                        //     location.href = `/${sessionStorage.getItem('locale')}/shopping_cart`
                        // }
                    })
            }
        )
    }

    hide_reservation () {
        let r = $ ('#reservation');
        r.removeClass ('show_reservation');
        r.addClass ('hidde_reservation')
    }

    show_hide_element (e) {
        let hide = $ ('#' + e.target.dataset.hide);
        let show = $ ('#' + e.target.dataset.show);

        hide.addClass ('ocultar');
        show.removeClass ('ocultar');
    }

    assistan_line (e) {
        let url = location.origin + `/${sessionStorage.getItem ('locale')}/help_me`;
        if (localStorage.hasOwnProperty ('chat_room')) {
            url = location.origin + `/${sessionStorage.getItem ('locale')}/help_me?id=` + sessionStorage.getItem ('chat_room');
        }

        location.href = url;
    }

    active_desactive_box_map (e) {
        let element = e.target.dataset;
        if (!element.hasOwnProperty ('id')) {
            element = e.target.parentNode.dataset;
        }
        $ (`#${element.target}`)[0].classList.toggle ('active');
    }

    saltarA(e) {
        let element = $(e.target.dataset.target);
        let top = parseFloat(element.offset().top) - 170;
        $("html, body").animate({scrollTop: top}, 1000);

        let button = $('button[data-target="#navbarText"]');
        button.trigger('click');
    }



    clear_session (e) {
        alert ('Vamos a limpiar la section');
        setSessionVar ('', '', 'clear', false).then (
            clear => {
                location.href = location.origin
            }
        )
    }


}