import {Controller} from "stimulus"
import $ from 'jquery';


export default class extends Controller {

    initialize() {
        window.WOW = require('wowjs').WOW;
        new WOW({live: false}).init()
    }
}