import {Controller} from "stimulus"
import $ from 'jquery';
import 'slick-carousel/slick/slick.min'




export default class extends Controller {

    connect() {
       $('.slick').slick({
           infinite: false,
           speed: 300,
           slidesToShow: 3,
           slidesToScroll: 1,
           rows: 3,
           responsive: [
               {
                   breakpoint: 1280,
                   settings: {
                       slidesToShow: 2,
                       slidesToScroll: 2,
                       infinite: true,
                       autoplay: true,
                       autoplaySpeed: 3000
                   }
               },
               {
                   breakpoint: 700,
                   settings: {
                       slidesToShow: 1,
                       slidesToScroll: 1,
                       rows: 1,
                       autoplay: true,
                       autoplaySpeed: 3000
                   }
               },

               // You can unslick at a given breakpoint now by adding:
               // settings: "unslick"
               // instead of a settings object
           ]
       })
    }

}