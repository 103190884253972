import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

export default class extends Controller {

    initialize() {
        let element = this.element;
        $.ajax({
            url: location.origin+'/'+sessionStorage.getItem('locale')+'/frequently-answer.json',
            data: {},
            type: 'GET',
            success: function (data) {
                let html = ''
                data.map(faq => {
                    html += `
                     <div class="card mb-1">
                              <div class="card-header" id="headingOne">
                                  <button class="mb-2 mt-2 collapsed" type="button" data-toggle="collapse" data-target="#faq_${faq.id}" aria-expanded="true" aria-controls="collapseOne">
                                    ${faq.title}
                                  </button>
                              </div>
                
                              <div id="faq_${faq.id}" class="collapse" aria-labelledby="headingOne" data-parent="#${element.id}">
                                <div class="card-body">
                                  ${faq.descriptions.body}
                                </div>
                              </div>
                            </div>
                    `;
                })
                element.innerHTML = html ;

            }
        })
    }
}