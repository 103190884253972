import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
// require('block-ui/jquery.blockUI')
export default class extends Controller {

    connect() {
        $('.c2ec_select').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción"
        });
        $('.c2ec_select_with_clear').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción",
            allowClear: true
        });

    }
}