import {Controller} from "stimulus"
import $ from 'jquery';



export default class extends Controller {

    connect() {
        let div = $('.change_background');
        if(div.length > 0){
            div.map((index,elem) => {
                let dataset = elem.dataset;
                $.ajax({
                    url: location.origin + `/${sessionStorage.getItem('locale')}/preferences/` + dataset.preference + '.json',
                    data: {
                        format: 'json'
                    },
                    type: 'GET',
                    success: function (data) {
                        if (data.image_url !== undefined) {
                            if(dataset.setBackground === "true"){
                                $(`#${dataset.item}`).css({
                                    'background-image': 'url("' + data.image_url + '")'
                                })
                            }else{
                                elem.setAttribute('data-background',data.image_url)
                            }

                        } else {
                            if(dataset.setBackground === "true") {
                                $(`#${dataset.item}`)[0].classList.add ('change-background');
                            }
                        }

                        // unblockBody();
                    }
                })
            })


        }else{
            // unblockBody();
        }


    }
}