import {Controller} from "stimulus"
import $ from 'jquery';



export default class extends Controller {

    initialize(){

    }

    connect() {

    }
}