import {Controller} from "stimulus"
import $ from 'jquery';

function formValid (valid) {
    let password = $ ('#user_password');
    let password_confirmation = $ ('#user_password_confirmation');
    let submit = $ ('input:submit');
    if (valid) {
        password.removeClass ('has-error')
        password.addClass ('has-success')
        password_confirmation.removeClass ('has-error')
        password_confirmation.addClass ('has-success')
        submit.attr ("disabled", false)
    } else {
        password.addClass ('has-error')
        password.removeClass ('has-success')
        password_confirmation.addClass ('has-error')
        password_confirmation.removeClass ('has-success')
        submit.attr ("disabled", true)
    }
}

function formRecoveryValid (valid) {
    let password = $ ('#user_password');
    let password_confirmation = $ ('#user_password_confirmation');
    let submit = $ ('input:submit');
    if (valid) {
        password.removeClass ('has-error')
        password.addClass ('has-success')
        password_confirmation.removeClass ('has-error')
        password_confirmation.addClass ('has-success')
        submit.attr ("disabled", false)
    } else {
        password.addClass ('has-error')
        password.removeClass ('has-success')
        password_confirmation.addClass ('has-error')
        password_confirmation.removeClass ('has-success')
        submit.attr ("disabled", true)
    }
}

function formRegisterValid (valid) {
    let password = $ ('#register_password');
    let password_confirmation = $ ('#register_password_confirmation');

    if (valid) {
        password.removeClass ('is-invalid')
        password_confirmation.removeClass ('is-invalid')
    } else {
        password.addClass ('is-invalid')
        password_confirmation.addClass ('is-invalid')
    }
}

export default class extends Controller {

    connect () {
        $ ('#user_password').keyup (this.validatePassword);
        $ ('#user_password_confirmation').keyup (this.validatePassword);

        $ ('#register_password').keyup (this.validatePasswordRegister);
        $ ('#register_password_confirmation').keyup (this.validatePasswordRegister);

        $ ('#password_recovery').keyup (this.validatePasswordRecovery);
        $ ('#password_recovery_confirmation').keyup (this.validatePasswordRecovery);

    }

    validatePassword () {
        if ($ ('#loginModal').is (':visible')) {
            let password = $ ('#user_password').val ();
            let password_confirmation = $ ('#user_password_confirmation').val ();

            if (password.length < 8) {
                formValid (false);
                $ ('input:submit').attr ("value", "Mínimo 8 caracteres.");
            } else {
                if (password === password_confirmation) {
                    formValid (true)
                    $ ('input:submit').attr ("value", "Cambiar contraseña.");
                } else {
                    formValid (false)
                    $ ('input:submit').attr ("value", "Las contraseñas no coinciden.");
                }
            }
        }
    }

    validatePasswordRecovery () {
        if($('#recovery_user_password_form').is(':visible')) {
            let password_recovery = $ ('#password_recovery').val ();
            let password_recovery_confirmation = $ ('#password_recovery_confirmation').val ();
            let button = $ ('#recovery_submit');
            if (password_recovery.length < 8) {
                formRecoveryValid (false);
                toastr.warning ("Mínimo 8 caracteres.");
                button.attr ('disabled', true);
            } else {
                if (password_recovery === password_recovery_confirmation) {
                    formRecoveryValid (true);
                    button.removeAttr ('disabled');
                } else {
                    formRecoveryValid (false)
                    toastr.error ("Las contraseñas no coinciden.");
                    button.attr ('disabled', true);
                }
            }
        }
    }

    validatePasswordRegister () {
        if($('#registerModal').is(':visible')) {
            let password_register = $ ('#register_password').val ();
            let password_register_confirmation = $ ('#register_password_confirmation').val ();
            let button = $ ('#form_register_submit');
            if (password_register.length < 8) {
                formRegisterValid (false);
                toastr.warning ("Mínimo 8 caracteres.");
                button.attr ('disabled', true);
            } else {
                if (password_register === password_register_confirmation) {
                    formRegisterValid (true);
                    button.removeAttr ('disabled');
                } else {
                    formRegisterValid (false)
                    toastr.error ("Las contraseñas no coinciden.");
                    button.attr ('disabled', true);
                }
            }
        }
    }


}