import {Controller} from "stimulus"
import $ from 'jquery';
import {unblockBody} from './blockUI_controller'



export default class extends Controller {

    connect() {
       let div = $('.backgroud-paralax');
       let childrens = div.children();
       $.ajax({
           url: '../'+div[0].dataset.img+'.json',
           data: {
               format: 'json'
           },
           type: 'GET',
           success: function (data) {

               let image = data.image_url || data.thumbnails_url;

               if(image !== undefined){
                   div.css({
                       'background-image': 'url("'+image+'")'
                   })
               }else{
                   childrens.map( (key, child) => {
                       child.classList.add('change-background')
                   })
               }

               unblockBody();

           }
       })

    }
}