import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from './sessionStorage_controller'
import {unblockBody} from './blockUI_controller'



export default class extends Controller {

    initialize(){
        if(sessionStorage.getItem('has_review') === undefined || sessionStorage.getItem('has_review') === null) {
            setSessionVar('', '', 'clear', false).then(
                result => {
                    unblockBody();
                }
            )
        }
    }


}