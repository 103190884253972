import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from 'bootbox/bootbox';
import {set_locale} from "./localize_controller";



let create_review = () => {
    bootbox.dialog({
        message: `
               <h4 class="text-center">Tu opinión es importante</h4><br>
               <div class="row">
                   <div class="col-12">
                       <form class="d-flex flex-column align-items-center justify-content-center">
                           <div class="d-flex justify-content-center" style="width: 100%">
                                <div class="rate">
                                    <input type="radio" id="star5" class="rating_radio" name="review[rating]" value="5" />
                                    <label for="star5" title="5">5 stars</label>
                                    <input type="radio" id="star4" class="rating_radio" name="review[rating]" value="4" />
                                    <label for="star4" title="4">4 stars</label>
                                    <input type="radio" id="star3" class="rating_radio" name="review[rating]" value="3" />
                                    <label for="star3" title="3">3 stars</label>
                                    <input type="radio" id="star2" class="rating_radio" name="review[rating]" value="2" />
                                    <label for="star2" title="2">2 stars</label>
                                    <input type="radio" id="star1" class="rating_radio" name="review[rating]" value="1" />
                                    <label for="star1" title="1">1 star</label>
                                </div>
                            </div>
                            <div class="" style="width: 100%">
                               <label for="reviewBody">Comentario</label><br>
                               <textarea id="reviewBody" class="form-control" name="review[reviewBody]" data-max="250" data-counter="counter_review_char" data-action="keyup->review#counter_characters paste->review#counter_characters"></textarea>
                                <span id="counter_review_char"></span>
                            </div>
                        </form>
                    </div>
                </div>
`,
        onEscape: false,
        buttons: {
            ok: {
                label: "<span class='fab fa-telegram'></span> Enviar",
                className: 'btn-success',
                callback: function(){
                    let rating = $('input.rating_radio');
                    let reviewBody = document.getElementById('reviewBody').value;
                    let reviewRating = 0;

                    rating.each((index) => {
                        if(rating[index].checked){
                            reviewRating = rating[index].value;
                        }
                    })

                    $.ajax({
                        url: `${location.origin}/admin/reviews.json`,
                        data: {
                            review: {
                                rating: reviewRating,
                                reviewBody: reviewBody
                            },
                            format: "json"
                        },
                        type: 'POST',
                        success: function (data) {
                            sessionStorage.setItem('has_review',true);
                            bootbox.alert({
                                message: `<div class="text-center"><span class="fa fa-star fa-3x text-warning mb-3"><br></span><h4> Gracias por su calificación!!!</h4></div>`,
                                buttons:{
                                    ok: {
                                        label: "",
                                        className: 'ocultar',
                                    }
                                }
                            })
                        }
                    })

                     }
            }
        }
    });

}


export default class extends Controller {


    connect() {
        set_locale().then(
            locale => {
                if(sessionStorage.getItem('has_review') === undefined || sessionStorage.getItem('has_review') === null){
                    if(document.getElementById('notificication_user') !== null){
                        create_review();
                    }
                }
            }
        )
    }

}