import {Controller} from "stimulus"
import $ from 'jquery';
import {set_locale} from './localize_controller'

let social_icon_object = {
   'facebook': 'icon-social-facebook',
    'whatsapp': 'fab fa-whatsapp',
    'twitter':'icon-social-twitter',
    'youtube':'icon-social-youtube',
    'instagram':'icon-social-instagram',
    'official' : 'icon-globe'
}

function tpl_social_button(type, url) {
    return `
     <a href="${url}" target="_blank" class="footer-link">
          <span class="${social_icon_object[type]}"></span>
        </a>
    `;
}

export default class extends Controller {

    connect() {
        set_locale().then(
            locale => {
                $.ajax({
                    url: `/${locale.locale}/preferences/to/social.json`,
                    data: {format: 'json'},
                    type: 'GET',
                    success: function (data) {
                        let social_area = $('.social-links');
                        let html = '';
                        data.map( element => {
                            html+= tpl_social_button(element.key.text,element.value);
                        })
                        social_area.map(area => {
                            social_area[area].innerHTML = html;
                        })



                    }
                })
            }
        )

    }
}