import {Controller} from "stimulus"
import $ from 'jquery';
import {loaded} from './swal_controller'




export default class extends Controller {

    connect() {
        loaded(true, '', '');
    }

    activeStep(e){
        let tab_step = e.target;
        let step_active = $('#'+tab_step.dataset.step)
        let active_body_tab = $('button.active');
        let active_body_step = $('div.step.active');

        active_body_tab[0].classList.remove('active');
        tab_step.classList.add('active');

        active_body_step[0].classList.remove('active');
        step_active[0].classList.add('active');

    }
}