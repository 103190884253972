import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from 'bootbox/bootbox';

export let get_popup = (key) => new Promise((resolve, reject) => {
    $.ajax({
        url: `/${sessionStorage.getItem('locale')}/get/popup`,
        data: {
            key: key
        },
        type: 'GET',
        success: function (data){
            if(data.success){
                resolve(data.data);
            }else{
                console.log(data);
            }
        }
    })
})


export default class extends Controller {

    connect() {

    }

}