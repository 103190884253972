import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from './sessionStorage_controller'
import {get_popup} from './popup_controller'
import {verification} from "./verificaciones_controller"
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'



String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
};

export let validate = (obj) => new Promise( (resolve, reject) => {

    let target = $('#'+obj);
    let required_field_input = target.find('input.required');
    let required_field_select = target.find('select.required');
    let required_field_checkbox = target.find('checkbox.required');
    let error = 0;

    let date_collect = $('#date_collect');
    let date_delivery = $('#date_delivery');


    required_field_input.each((index, elem) => {
        let field = $(elem)[0];
        let helper = $(field.parentNode).find('.helper-text');


        if (field.value.isEmpty()) {
            // helper.html('<p class="text text-danger">Este campo no puede estar vacio</p>');
            field.classList.add('is-invalid');
            error++;
        } else {
            if(!field.classList.contains('datepicker')){
                field.classList.remove('is-invalid');
                helper.html('');
            }
        }
    });

    required_field_select.each((index, elem) => {
        let field = $(elem)[0];
        let helper = $(field.parentNode).find('.helper-text');
        if (field.value.isEmpty()) {
            if(field.classList.contains('select2-hidden-accessible')){
                let select_2_field = $(field)[0].parentNode;
                let parent_node = $(select_2_field).find('.select2-selection');
                parent_node[0].classList.add('is-invalid');
            }
            // helper.html('<p class="text text-danger">Este campo no puede estar vacio</p>');
            $(field.parentNode).addClass("is-invalid");
            error++;
        } else {
            if(field.classList.hasOwnProperty('select2-hidden-accessible')){
                if(field.classList.contains('select2-hidden-accessible')){
                    let select_2_field = $(field)[0].parentNode;
                    let parent_node = $(select_2_field).find('.select2-selection');
                    parent_node[0].classList.remove('is-invalid');
                }
            }
            // helper.html('');
            $(field.parentNode).removeClass("is-invalid");
            // helper.html('');
        }
    });

    required_field_checkbox.each((index, elem) => {
        let checkbox = $(elem)[0];
        let helper = $(checkbox).find('.helper-text');
        if (checkbox.value.isEmpty()) {
            // helper.html('<p class="text text-danger">Este campo no puede estar vacio</p>');
            $(checkbox).addClass("is-invalid");

            error++;
        } else {
            helper.html('');
            $(checkbox).removeClass("is-invalid");
            // helper.html('');
        }
    });


    if(date_collect.val() !== undefined && date_delivery.val() !== undefined){
        let datepicker = $('.datepicker');
        if(!date_collect.val().isEmpty() && !date_delivery.val().isEmpty()){
            if(new Date(date_collect.val()) >= new Date(date_delivery.val())){
                error++;

                datepicker.each((index, elem) => {
                    let date = $(elem)[0];
                    let helper_date = $(date.parentNode).find('.helper-text');

                    // helper_date.html('<p class="text text-danger">La fecha de recogida no puede ser mayor o igual que la fecha de entrega.</p>');

                    date.classList.add('is-invalid');


                });
            }else{
                datepicker.each((index, elem) => {
                    let date = $(elem)[0];
                    // let helper_date = $(date.parentNode).find('.helper-text');

                    // helper_date.html('');
                    date.classList.remove('is-invalid');
                });
            }
        }

    }

    if(error === 0){
        resolve({
            next: true
        })
    }else{
        resolve({
            next: false
        })
    }
})

export let validate_passport_number = (passport,elem, button) => new Promise((resolve, reject) => {
    // expresion regular
    let regex = /^[a-zA-Z0-9]{1,11}$/g;
    let submit_button = $(`#${button}`);
    let element = $(`#${elem}`);
    // VALIDACION
    if(regex.test(passport)){
        element[0].classList.remove('is-invalid');
        if(submit_button.length > 0){
            submit_button.removeAttr('disabled');
        }
    }else{
        element[0].classList.add('is-invalid');
        if(submit_button.length > 0) {
            submit_button.attr ('disabled', true);
        }
    }
})

let show_notify = () => new Promise((resolve, reject) => {

    get_popup('with_rent').then(
        with_rent => {
            Swal.fire({
                title: with_rent.title || 'Proceso de pago',
                text: with_rent.description || "Estimado cliente usted cuenta con un proceso de renta activo, ¿Desea continuar con este proceso y desechar el antiguo?",
                icon: with_rent.icon || 'question',
                showCancelButton: true,
                confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${I18n.t('global.popup.with_rent_confirm')}`,
                cancelButtonText: `<i class="fa fa-thumbs-down"></i> ${I18n.t('global.popup.with_rent_cancel')}`,
                reverseButtons: true,
                allowOutsideClick: true,
                // backdrop: `#354052`,
                backdrop: `rgba(255,255,255,1)`,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary margin-right-15'
                },
                buttonsStyling: false
            }).then( async (result) => {
                if (result.isConfirmed) {
                    await setSessionVar("shopping_cart_reference","","delete", false);
                    await setSessionVar("has_reservation","","delete", false);
                    await setSessionVar("reservation_time","","delete", false);
                    let reservation_div = $('#reservation');
                    reservation_div.addClass('hidde_reservation');
                    reservation_div.removeClass('show_reservation');
                    $('#show_reservation').css({'display': 'none'});
                    resolve({
                        let_continue: true
                    })

                }
                if(result.isDismissed){
                    resolve({
                        let_continue: false
                    })

                }
            })
        }
    )

})

let check = () => new Promise((resolve, reject) => {
     setSessionVar('inPayProccess','false','add',false).then(
         inPayProcess => {
             if(inPayProcess.success){
                 setSessionVar('has_reservation',true,'add',false).then(
                     result => {
                         if(result.success){
                             resolve({next: true})
                         }else{
                             console.log(result);
                         }
                     }
                 )
             }else{
                 console.log(inPayProcess);
             }
         }
     )


})




export default class extends Controller {

    initialize() {
    }

    validate(e) {
        e.preventDefault();
        let form = e.target.dataset.form;
        let next = validate(e.target.dataset.validate);
        if ( next.next ){
            check(form).then();
        }
    }

    validate_passport(e){
        validate_passport_number(e.target.value,e.target.id, e.target.dataset.block).then();
    }

    validateCheck(e) {
        e.preventDefault();
        let form = e.target.dataset.form;
        let next = validate(e.target.dataset.validate).then(
            validate => {
                if(validate.next){
                    $('#'+form).submit();
                }
            }
        );
    }

    validateForm(e){
        e.preventDefault();
        verification().then(
            verif_result => {
                if(verif_result.validate){
                    let form = e.target.dataset.form;
                    if(location.pathname === "/" && sessionStorage.hasOwnProperty("shopping_cart")){
                        show_notify().then(result => {
                            if(result.let_continue){
                                validate(e.target.dataset.validate).then(
                                    validate => {
                                        if(validate.next){
                                            check(form).then(
                                                check => {
                                                    next_step_after_check_form(form);
                                                }
                                            );
                                        }
                                    }
                                )
                            }
                            else{
                                location.href = `/${sessionStorage.getItem('locale')}/reserva`;
                            }
                        })
                    }
                    else{
                        validate(e.target.dataset.validate).then(
                            validate => {
                                if(validate.next){
                                    check(form).then(
                                        check => {
                                            if(check.next){
                                                next_step_after_check_form(form);
                                            }
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            }
        )


    }

}