import {Controller} from "stimulus"
import $ from 'jquery';

import popper from '@popperjs/core'



export default class extends Controller {

    connect() {
       $('#enable_analitics').on('click',function () {
          $.ajax({
              url: location.origin+'/enable_analytics',
              data: {format: 'json'},
              type: 'POST',
              success: function (data) {
                  if(data.success){
                      let cookie_card = $('#cookie_card');
                      sessionStorage.setItem('enable_analytics','true');
                      cookie_card[0].classList.remove('active');
                      setTimeout(function () {
                          cookie_card.remove();
                      },200);
                  }
              }
          })
       })
    }
}