import {Controller} from "stimulus"
import $ from 'jquery';

import {unblockBody} from './blockUI_controller'

export default class extends Controller {

    connect() {
       // unblockBody();
    }
}