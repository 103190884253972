import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from "bootbox";

let ongoingTouches = [];
function colorForTouch(touch) {
    let id = touch.identifier;
    id = id.toString(16); // make it a hex digit
    return "#" + id + id + id;
}

function ongoingTouchIndexById(idToFind) {
    for (let i=0; i<ongoingTouches.length; i++) {
        let id = ongoingTouches[i].identifier;

        if (id === idToFind) {
            return i;
        }
    }
    return -1;    // not found
}



export default class extends Controller {

    connect() {
        this.sign = document.getElementById('digital_signature');
        if(this.sign !== null){
            this.content = this.sign.getContext("2d");

            this.sign.addEventListener('mousedown', this.starDrawing.bind(this));
            this.sign.addEventListener('mouseup', this.stopDrawing.bind(this));
            this.sign.addEventListener('mouseleave', this.stopDrawing.bind(this));
            this.sign.addEventListener('mousemove', this.draw.bind(this));

            this.sign.addEventListener('touchstart', this.handleStart.bind(this), false);
            this.sign.addEventListener('touchend', this.handleEnd.bind(this), false);
            this.sign.addEventListener('touchleave', this.handleCancel.bind(this), false);
            this.sign.addEventListener('touchmove', this.handleMove.bind(this), false);
        }

    }

    realXClient(clientX){
        return clientX - this.sign.getBoundingClientRect().left;
    }
    realYClient(clientY){
        return clientY - this.sign.getBoundingClientRect().top;
    }

    starDrawing(event) {
        this.isDrawing = true;
        this.lastX = this.realXClient(event.clientX);
        this.lastY = this.realYClient(event.clientY);
    }

    handleStart(evt) {
        evt.preventDefault();

        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        this.isDrawing = true;
        let touches = evt.changedTouches;
        this.lastX = this.realXClient(touches[0].clientX);
        this.lastY = this.realYClient(touches[0].clientY);
    }

    stopDrawing(event) {
        this.isDrawing = false;
        this.lastX = this.realXClient(event.clientX);
        this.lastY = this.realYClient(event.clientY);
    }

    handleEnd(evt) {
        document.getElementsByTagName('body')[0].style.overflow = 'visible';

        let touches = evt.changedTouches;

        this.isDrawing = false;
        this.lastX = this.realXClient(touches[0].clientX);
        this.lastY = this.realYClient(touches[0].clientY);
    }

    handleCancel(evt) {
        document.getElementsByTagName('body')[0].style.overflow = 'visible';
        let touches = evt.changedTouches;

        this.lastX = this.realXClient(touches[0].clientX);
        this.lastY = this.realYClient(touches[0].clientY);
    }



    draw(event) {
        if (!this.isDrawing) return;
        this.drawData(this.realXClient(event.clientX), this.realYClient(event.clientY));
    }

    handleMove(evt) {

        let touches = evt.changedTouches;
        if (!this.isDrawing) return;
        this.drawData(this.realXClient(touches[0].clientX), this.realYClient(touches[0].clientY));
    }


    drawData(x, y) {
        this.content.lineJoin = "round";
        this.content.lineCap = "round";
        this.content.lineWidth = 2;
        this.content.strokeStyle = '#08387e';
        this.content.beginPath();
        this.content.moveTo(this.lastX, this.lastY);
        this.content.lineTo(x, y);
        this.content.stroke();
        this.content.closePath();
        this.lastX = x;
        this.lastY = y;
    }

    getSignature(){
        bootbox.dialog({
            message: ` <div class="" data-controller="signature">
                       <canvas id="digital_signature" width="450"></canvas>
                       </div>
`,
            onShow: function(e) {
                // console.log('me mostre');
            },
            onEscape: false,
            buttons: {
                ok: {
                    label: "Crear firma",
                    className: 'btn-info',
                    callback: function(){
                        let dataUrl = document.getElementById('digital_signature').toDataURL()
                        document.getElementById('signature_img_url').innerHTML = `<img src="${dataUrl}" alt="Signature Canvas" style="width: 100%"><br>
                                                                            URL: ${dataUrl}
                                                                            `

                    }
                }
            }
        });

    }
    imgSignature(){
        let dataUrl = this.sign.toDataURL()
        document.getElementById('signature_img_url').innerHTML = `<img src="${dataUrl}" alt="Signature Canvas"><br>
                                                                            URL: ${dataUrl}
                                                                            `

    }
    cleanSignature(){
        this.sign.width = this.sign.width;
    }
}