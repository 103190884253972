import {Controller} from "stimulus"
import $ from 'jquery';
import I18n from "i18n-js";

export let set_locale = () => new Promise((resolve, reject)=>{
    //Chequear el idioma en el cual se quiere mostrar la informacion
    let locale = $('body').data('locale');
    sessionStorage.setItem('locale',locale);
    I18n.locale = locale;
    //FIN DE CHEUQEO

    resolve({
        success: true,
        locale: locale
    })
})


export default class extends Controller {

    connect() {

    }

}