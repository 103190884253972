import consumer from "./consumer"

document.addEventListener('turbo:load', () => {
  if (location.pathname.includes('/installation')){
    const channel_room = consumer.subscriptions.create({channel: "InstallationChannel"}, {
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        document.getElementById('progress-span').innerText = data.text;
        let progress = document.getElementById('progress-bar');
        progress.style.width = `${data.percent}%`;
        document.getElementById('progress-percent').innerText = `${data.percent}%`;

        if (parseInt(data.percent) >= 30 && parseInt(data.percent) < 60) {
          progress.classList.add('bg-warning');
          progress.classList.remove('bg-danger');
          document.getElementById('progress-percent').style.color = '#333';
        }

        if (parseInt(data.percent) >= 60 && parseInt(data.percent) < 90) {
          progress.classList.add('bg-info');
          progress.classList.remove('bg-warning');
          document.getElementById('progress-percent').style.color = '#fff';
        }
        if (parseInt(data.percent) >= 90) {
          progress.classList.add('bg-success');
          progress.classList.remove('bg-info');
        }
        if( parseInt(data.percent) === 100){
          document.getElementById('progress-span').innerText = 'Carga de datos completado!!!'
          let btn_continue = document.getElementById('btn-step-one');
          btn_continue.style.display = 'inline-block';
          btn_continue.classList.remove('disabled');
        }
      }
    });
  }

})
