import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

export default class extends Controller {

    connect() {

        let user_country_code = $('#user_country_code:not(.hidden)');
        let user_country_id = $('#user_country_id');
        let register_country_id = $('#register_country_id');

        if(user_country_code.length > 0){
            user_country_code.select2({
                theme: "bootstrap",
                language: sessionStorage.getItem ('locale'),
                placeholder:'* Codigo ISO del País',
                search: false,
                ajax: {
                    url: `${location.origin}/${sessionStorage.getItem ('locale')}/config/countries`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            search: params.term,
                            page: params.page || 1
                        }

                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = []
                        params.page = params.page || 1;

                        data.items.map(elem => {
                            result.push(
                                {
                                    id: elem.code,
                                    text: elem[`name_${sessionStorage.getItem ('locale')}`]
                                }
                            );
                        })

                        if (data.total > 10) {

                            return {
                                results: result,
                                "pagination": {
                                    "more": true
                                }
                            }
                        } else {
                            return {
                                results: result,
                                "pagination": {
                                    "more": false
                                }
                            }
                        }

                    }
                }
            }).on('change',function (e) {

            });
        }

        if(user_country_id.length > 0){
            user_country_id.select2({
                theme: "bootstrap",
                language: "es"
            }).on('change',function (e) {
                $.ajax({
                    url: `/${sessionStorage.getItem('locale')}/config/countries/`+e.target.value,
                    data: { format: "json"},
                    type: "GET",
                    success: function (data) {
                        $("#user_country_code").val(data.code);
                        $("#user_phone").val(data.dial_code);
                    }
                })

            });
        }

        if(register_country_id.length > 0) {
            register_country_id.select2 ({
                theme: "bootstrap",
                language: "es"
            }).on ('change', function (e) {
                $ ('#register_country_id_selected').val (e.target.value);
                $.ajax ({
                    url: `/${sessionStorage.getItem ('locale')}/config/countries/` + e.target.value,
                    data: {format: "json"},
                    type: "GET",
                    success: function (data) {
                        $ ("#register_country_code").val (data.code);
                        $ ("#register_phone").val (data.dial_code);
                    }
                })

            });
        }
    }
}