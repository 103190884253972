import {Controller} from "stimulus"
import $ from 'jquery';
import {set_locale} from "./localize_controller";
import bootbox from "bootbox/bootbox";
import consumer from "../channels/consumer";

let notifications_array = [];
let colors = {
    'Urgente': '#ef4035',
    'Advertencia': '#e9a04e',
    'Notificacion': '#797776'
}

let mark_as_read = (id = null) => {
    let items = document.getElementsByClassName('carousel-item');
    let identificador = '';
    for(var i=0;i<items.length;i++){
        if(items[i].classList.contains('active')){
            if(id === null){
                identificador = items[i].getAttribute('data-notification-id');
            }else{
                identificador = id;
            }

            $.ajax({
                url: `${location.origin}/${sessionStorage.getItem('locale')}/read/notification_push/${identificador}`,
                data:{ format: 'json'},
                type: 'GET',
                success: function (data) {
                    // console.log(data);
                }
            })
        }
    }

}
let show_alerts = (elem) => {
    let indicator = '';
    let carousel = '';

    // array.map((elem, key) => {
    //     let active = '';
    //     if (key === 0) {
    //         active = 'active';
    //     }
        if (elem.icon.includes('fa')) {
            elem.icon = `fa ${elem.icon}`;
        }

        indicator += `<li data-target="#carouselNotification" data-slide-to="0" class="active mark_ad_read" data-read-id="${elem.id}"></li>`
        carousel += ` <div class="carousel-item active" data-interval="30000" data-notification-id="${elem.id}">
                               <div class="carousel-body">
                                    <p class="body-title" style="--icon-color:${colors[elem.type]};"><span class="${elem.icon} text-justify carousel-icon mr-2" ></span> ${elem.title}</p>
                                   <p>${elem.description}</p>
                                </div>

                            </div>`
    // })

    bootbox.dialog({
        message: `<button class="close-modal-login" type="button" data-dismiss="modal"></button>
                        <div id="carouselNotification" class="carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                           ${indicator}
                          </ol>
                          <div id="carousel-inner" class="carousel-inner">
                           ${carousel}
                          </div>
                        </div>
`,
        onShow: function (e) {
            mark_as_read();
            let mar = document.getElementsByClassName('mark_ad_read');
            for(var i=0;i<mar.length;i++){
                mar[i].addEventListener('click',function (e) {
                    mark_as_read(e.target.dataset.readId);
                })
            }
        },
        onEscape: false,
        buttons: {
            ok: {
                label: "<span class='fa fa-check'></span> Leidos",
                className: 'btn-info',
                callback: function () {
                    // console.log('Custom OK clicked');
                }
            }
        }
    });

}

let count_and_show_data_notification = (array, count_area, message_area) => new Promise((result, reject) => {
    let html = '';
    if(array.length > 0){
        count_area.classList.add('badge-error');
        $(count_area).css('--color-red','rgba(239, 64, 53, 1)');

        count_area.innerText = array.length;
        notifications_array = array;


        array.map((element,index) => {
            if (element.icon.includes('fa')) {
                element.icon = `fa ${element.icon}`;
            }

           html+= `<a href="javascript:;" class="dropdown-item" data-action="notificationPush#show" data-position="${index}" style="display: grid;
                                                            grid-template-columns: .1fr 1fr .2fr;
                                                            grid-auto-flow: dense;gap: 10px;">
                    <span data-position="${index}"  class="${element.icon}" style="background: ${colors[element.type]};
                                                            border-radius: 50%;
                                                            width: 18px;
                                                            height: 18px;
                                                            position: relative;
                                                            display: inline-flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            padding: 0 0 0 3px;
                                                            color: white;"></span>
                    <span data-position="${index}"> ${element.title} </span>
                    <span data-position="${index}" class="text-muted" style="font-size: .9em;">   ${element.date}</span>
                    </a>`
        })

    }else{
        count_area.classList.remove('badge-error');
        $(count_area).css('--color-red','#b5b0b0');
        html+= `<a href="javascript:;" class="dropdown-item" style="display: grid;
                                                            grid-template-columns: .1fr 1fr .2fr;
                                                            grid-auto-flow: dense;gap: 10px;">
                    <span   class="icon-speech" style="background: #666;
                                                            border-radius: 50%;
                                                            width: 18px;
                                                            height: 18px;
                                                            position: relative;
                                                            display: inline-flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            padding: 0 0 0 3px;
                                                            color: white;"></span>
                    <span > No hay notificaciones para mostrar </span>
                    <span  class="text-muted" style="font-size: .9em;">   </span>
                    </a>`
    }

    message_area.innerHTML = html;
})


export default class extends Controller {

    initialize () {
        let notifiy_user = document.getElementById('user_notification_id');
        let notification_count = document.getElementById('notification_push_count');
        let notification_message = document.getElementById('notification_push_message');

        if (notifiy_user !== null) {
            if (!location.pathname.includes('admin') || !location.pathname.includes('maintenance') || !location.pathname.includes('initial')) {
                const user_id = Number(notifiy_user.getAttribute('data-user-id'));
                const notification_push = consumer.subscriptions.create({
                    channel: "NotificationPushChannel",
                    user_id: user_id
                }, {
                    connected() {
                        $.ajax({
                            url: `${location.origin}/${sessionStorage.getItem('locale')}/get/notification_push`,
                            data: {format: 'json'},
                            type: 'GET',
                            success: function (data) {
                                count_and_show_data_notification(data, notification_count, notification_message).then()
                            }
                        })
                    },

                    disconnected() {
                        // Called when the subscription has been terminated by the server
                    },

                    received(data) {
                        switch (data.type) {
                            case 'push':
                                const tone_message = document.getElementById('tone_message');
                                tone_message.play();
                                notifications_array.unshift(data.notification);
                                notification_count.classList.add('agrandar');
                                setTimeout(() => {
                                    notification_count.classList.remove('agrandar');
                                }, 2500)
                                count_and_show_data_notification(notifications_array,notification_count, notification_message).then();
                                break;
                            case 'connection':
                                let datos = data.notification;
                                toastr[datos.type](datos.msg,datos.error)
                                break;
                        }
                    }
                });
            }
        }
    }

    connect() {

    }

    show(e){
        show_alerts(notifications_array[e.target.dataset.position]);
    }

    setAsRead(e){
        $.ajax({
            url: `${location.origin}/${sessionStorage.getItem('locale')}/read/notification_push/${e.target.dataset.id}`,
            data:{ format: 'json'},
            type: 'GET',
            beforeSend: function () {
              $(e.target)[0].innerHTML = `<span class="fa fa-circle-o-notch fa-spin"></span>`
            },
            success: function (data) {
                if(data.success){
                    let target = $(e.target);
                    let notification_status = $(`#notification_status_${e.target.dataset.id}`);
                    target[0].classList.remove('btn-info');
                    target[0].classList.add('btn-success');
                    target[0].innerHTML = `<span class="fa fa-check"></span>`

                    notification_status[0].innerHTML = `<span class="badge badge-secondary">Leido</span>`;

                    setTimeout(()=>{
                        target.fadeTo(1000).delay(1000).remove();
                    },1000);
                }

            }
        })
    }
}