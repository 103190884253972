import {Controller} from "stimulus"
import $ from 'jquery';

export let setSessionVar = (name, value, action, is_date, save = true, samedate = false, add_days_or_hours = 1) => new Promise((resolve, reject) => {

    let data_body = {
        name: name,
        value: value,
        accion: action,
        is_date: is_date,
        samedate: samedate,
        add_days_or_hours: add_days_or_hours
    }

    $.ajax({
        url: location.origin + '/config/set_session_var',
        data: data_body,
        type: 'GET',
        success: function (data) {
            if (data.success) {
                switch (action) {
                    case 'add':
                        if (save) {
                            if (typeof data.value === 'object') {
                                for (const prop in data.value) {
                                    sessionStorage.setItem(prop, data.value[prop]);
                                }
                            } else {
                                sessionStorage.setItem(name, data.value);
                            }
                        }

                        break;
                    case 'delete':
                        sessionStorage.removeItem(name);
                        break;
                    case 'clear':
                        sessionStorage.clear();
                        break;
                }

                resolve(data);
            }
        },
        error: function (error) {
            resolve({success: false});
        }
    });
})


export let checkSessionVar = (variable) => new Promise((resolve, reject) => {

    $.ajax({
        url: location.origin + `/config/check_session_var`,
        data: {
            variable: variable
        },
        type: 'GET',
        success: async function (data) {
            if (data.success) {
                resolve({success: true});
            } else {
                await sessionStorage.removeItem(data.data);
                resolve({success: false});
            }
        }
    });

    return false;
})

export let checkSessionVarWithData = (variable) => new Promise((resolve, reject) => {

    $.ajax({
        url: location.origin + `/config/check_session_var`,
        data: {
            variable: variable
        },
        type: 'GET',
        success: async function (data) {
            if (data.success) {
                resolve(data);
            } else {
                await sessionStorage.removeItem(data.data);
                resolve(data);
            }
        }
    });

    return false;
})


export default class extends Controller {


}