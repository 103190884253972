import {Controller} from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

export let catalog = (shuffle = false, limit=10, offset = 0) => new Promise((resolve, reject) =>{
    $.ajax({
        url: `/${sessionStorage.getItem('locale')}/admin/catalogs/catalog`,
        data: {
            format: 'json',
            catalog: true,
            shuffle: shuffle,
            limit: limit,
            offset: offset
        },
        type: 'GET',
        success: function (catalog) {
            if(catalog.total > 0){
                resolve({
                    success: true,
                    data: catalog.rows,
                    more: catalog.more,
                    limit: catalog.limit,
                    offset: catalog.offset,
                    code: 200
                });
            }else{
                resolve({
                    success: false,
                    data: {},
                    more: false,
                    limit: 0,
                    offset: 0,
                    code: 500
                })
            }
        }
    })
})

export let preferences = (text, type) =>new Promise((resolve, reject)=>{
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/get_preference`,
        data: {
            format: 'json',
            text: text,
            type: type
        },
        type: 'GET',
        success: function (data) {
            resolve(data[0]);
        }
    })
})
export default class extends Controller {

    initialize(){
        let category_field = $('#catalog_category');
        let category_id_field = $('#catalog_categoryid');
        if(location.pathname.includes('edit')){
            let option = new Option(category_field.val(), category_id_field.val(), true, true);
            $('#catalog_select_category_aux').append(option).trigger('change');
        }else{
            category_field.val('');
            category_id_field.val('');
        }


        if(this.element.dataset.show === 'true'){
            catalog (true, 5, 0).then (
                catalogo => {
                    preferences ('no_form', 'modules').then (
                        noForm => {
                            let html = '';
                            if (catalogo.success) {
                                let data = catalogo.data;
                                let count = 0;
                                data.map (product => {


                                    let extra_class = '';
                                    let array_class = ['grid-col-2', 'grid-row-2','']

                                    if (count === Math.floor (Math.random () * data.length)) {
                                        extra_class = array_class[Math.floor (Math.random () * array_class.length)];
                                    }

                                    html += this.tpl_catalog (product.id,product.productId, product.category, product.category_id, product.picture, product.price, product.description, noForm.active, product.count_active, extra_class);

                                    count = count + 1;
                                })
                            }

                            let btn_more = $ ('#btn_more_result_catalog');

                            if (btn_more.length > 0 && catalogo.more) {
                                btn_more[0].setAttribute ('data-limit', catalogo.limit)
                                btn_more[0].setAttribute ('data-offset', catalogo.offset)
                            } else {
                                btn_more.remove ();
                            }

                            this.element.innerHTML = html;
                        }
                    );

                }
            )
        }


    }

    connect() {
        let category_field = $('#catalog_category');
        let category_id_field = $('#catalog_categoryid');


        $('#catalog_select_category_aux').select2({
            theme: "bootstrap",
            allowClear: true,
            ajax: {
                url: location.origin+`/${sessionStorage.getItem('locale')}/admin/products/get_categories_active`,
                data: {
                   format: 'json'
                },
                type: "GET",
                processResults: function (data, params) {
                    let result = [];

                   data.map( elem => {
                       result.push({
                           id: elem.code+'|'+elem.name,
                           text: elem.name
                       })
                   })

                        return {
                            results: result
                        }


                }
            }
        }).on('change', function (e) {
            let value = e.target.value;
            value = value.split('|');
            category_field.val(value[value.length-1]);
            $('#select2-catalog_select_category_aux-container').text(value[value.length-1]);
            value.pop();
            category_id_field.val(value.join('|'));
        })


    }

    get_more_data (e) {
        let data = e.target.dataset;
        let btn_more = $ ('#btn_more_result_catalog');
        let container = $ ('#catalogo_container');
        preferences ('no_form', 'modules').then (
            noForm => {
                catalog (true, data.limit, data.offset).then (
                    catalogo => {
                        let html = '';
                        if (catalogo.success) {
                            let data = catalogo.data;
                            let count = 0;
                            data.map (product => {
                                let extra_class = '';
                                let array_class = ['grid-col-2', 'grid-row-2','']

                                if (count === Math.floor (Math.random () * data.length)) {
                                    extra_class = array_class[Math.floor (Math.random () * array_class.length)]
                                }

                                html += this.tpl_catalog (product.id,product.productId, product.category, product.category_id, product.picture, product.price, product.description, noForm.active, product.count_active, extra_class);

                                count = count + 1;
                            })
                        }

                        let btn_more = $ ('#btn_more_result_catalog');

                        if (btn_more.length > 0 && catalogo.more) {
                            btn_more[0].setAttribute ('data-limit', catalogo.limit)
                            btn_more[0].setAttribute ('data-offset', catalogo.offset)
                        } else {
                            btn_more.remove ();
                        }

                        container.append (html);
                    }
                )
            })
    }

    tpl_catalog (id, productId,category, category_id, picture, price, description, showButton, count_active, extra_class) {
        let buyBtn = '';
        let btn_text = I18n.t ('main.body.catalog.rent_btn');
        if (showButton && parseInt (count_active) > 0) {
            buyBtn = `<a href="${location.origin}/${sessionStorage.getItem ('locale')}/shopping_cart/${productId}/details" >${btn_text}</a>`;
        } else {
            buyBtn = ` <a href="javascrip:;" >${I18n.t ('main.body.catalog.no_available')}</a>`
        }

        let des = description;
        // des = des.split ('<div>');
        // des = des[1].slice (0, 150) + "...";
        des = des.slice(0,175)+"..."
        des = des.split('<div>').join('');
        des = des.split('</div>').join('');
        return `
                    <div class="product ${extra_class}">
                        <div class="name">
                            ${category} 
                            <p>Desde $${price}.00</p>
                        </div>
                        <div class="image">
                          <img src="${picture}" alt="${category}">
                        </div>
                        <div class="description">
                          <div class="description-info">
                            ${des}
                          </div>
                          <div class="description-btn">
                             ${buyBtn}
                          </div>
                        </div>
                        <div class="overlay"></div>
                    </div>
                
        `
    }



}