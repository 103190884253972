import { Controller } from "stimulus"
import $ from 'jquery';
import {validate_email} from "./verificaciones_controller";

let refresh_form = () => {
    let login_container = $(`#login_form_container`)
    let forgot_my_password_container = $(`#forgot_my_password_container`)

    let login_form = $('#login_form');
    let email = login_form.find('input#email');
    let password = login_form.find('input#password');
    let remember_me = login_form.find('input#remember_me');

    let recovery_form = $('#recovery_form');
    let email_recovery = recovery_form.find('input#email_recover');

    login_container[0].classList.remove('ocultar');
    forgot_my_password_container[0].classList.add('ocultar');

    email[0].setAttribute('value','');
    password[0].setAttribute('value','');
    remember_me.prop('checked',false);
    email_recovery[0].setAttribute('value','');

}

export default class extends Controller {

    connect() {
        let login = $('#login_submit');
        let recover_password = $('#recover_password_submit');
        let close_btn_modal = $('.close-modal-login');

        login.on('click', function (e) {

            let login_form = $('#login_form');
            let authenticity_token = login_form.find('input[name="authenticity_token"]');
            let email = login_form.find('input#email');
            let password = login_form.find('input#password');
            let remember_me = login_form.find('input#remember_me');

            validate_email(email.val()).then(
                email_validate => {
                    if(email_validate){
                        login[0].innerHTML = '<span class="fa fa-circle-notch fa-spin"></span>';
                        email[0].classList.remove('is-invalid');
                        $.ajax({
                            url: `${location.origin}/login`,
                            type: 'POST',
                            data: {
                                "authenticity_token" : authenticity_token.val(),
                                "email": email.val(),
                                "password": password.val(),
                                "remember_me": remember_me.is(':checked'),
                                "commit": "Iniciar sesión",
                                "controller": "sessions",
                                "action": "create",
                                "locale": sessionStorage.getItem('locale'),
                                "format": 'json'
                            },
                            beforeSend : function () {

                            },
                            success : function (data) {
                                if(data.success){
                                    // location.href = `${location.origin}${data.redirect}`
                                    location.reload();
                                }else{
                                    toastr.error(data.message, data.code);
                                    login[0].innerHTML = I18n.t('login_and_register.login.title');

                                }
                            }
                        })
                    }else{
                        toastr.error('Email no válido');
                        email[0].classList.add('is-invalid');
                    }
                }
            )


        })
        recover_password.on('click', function () {
            let recovery_form = $('#recovery_form');
            let authenticity_token = recovery_form.find('input[name="authenticity_token"]');
            let email = recovery_form.find('input#email_recover');

            validate_email(email.val()).then(
                email_validate => {
                    if(email_validate){
                        email[0].classList.remove('is-invalid');
                        $.ajax({
                            url: recovery_form[0].action,
                            type: recovery_form[0].method,
                            data: {
                                "authenticity_token" : authenticity_token.val(),
                                "email": email.val(),
                                "locale": sessionStorage.getItem('locale'),
                                "format": 'json'
                            },
                            beforeSend : function () {

                            },
                            success : function (data) {
                                if(data.success){
                                    toastr.success(data.message, data.code);
                                    if(close_btn_modal.length > 0){
                                        close_btn_modal.map((index, elem) => {
                                            $(elem).trigger('click');
                                        })

                                    }
                                }else{
                                    toastr.error(data.message, data.code);
                                }
                            }
                        })
                    }else{
                        toastr.error('Email no válido');
                        email[0].classList.add('is-invalid');
                    }
                });
        })
        close_btn_modal.map((index, elem) => {
            $(elem).on('click', function () {
                refresh_form();
            });
        })
    }

    show_password(e){
       let elem = $(e.target);
       let field = $(`#${e.target.dataset.target}`);
       if(field[0].type === "password"){
          field[0].setAttribute('type','text');
          elem[0].classList.remove('fa-eye');
          elem[0].classList.add('fa-eye-slash');
       }else{
           field[0].setAttribute('type','password');
           elem[0].classList.remove('fa-eye-slash');
           elem[0].classList.add('fa-eye');
       }
    }

}