import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from 'bootbox'

let i = 0;
let progress = document.getElementById('progress-bar');
let span = document.getElementById('progress-span');

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

let save_countries = () => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/initial/installation/save/countries`,
        data: {format: 'json'},
        type: 'POST',
        success: function (result) {
            if (result.success) {
                if (result.success) {

                    resolve({
                        next: true
                    })
                }
            }
        }
    })
})
let save_keys = () => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/initial/installation/save/keys`,
        data: {format: 'json'},
        type: 'POST',
        success: function (result) {
            if (result.success) {

                resolve({
                    next: true
                })
            }
        }
    })
})
let save_types = () => new Promise((resolve, reject) => {

    $.ajax({
        url: `${location.origin}/initial/installation/save/types`,
        data: {format: 'json'},
        type: 'POST',
        success: function (result) {
            if (result.success) {

                resolve({
                    next: true
                })
            }
        }
    })
})
let save_api_url = () => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/initial/installation/save/api_url`,
        data: {format: 'json'},
        type: 'POST',
        success: function (result) {
            if (result.success) {
                resolve({
                    next: true
                })
            }
        }
    })
})
let save_popup = () => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/initial/installation/save/popup_message`,
        data: {format: 'json'},
        type: 'POST',
        success: function (result) {
            if (result.success) {

                resolve({
                    next: true
                })
            }
        }
    })
})
let save_modules = () => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/initial/installation/save/modules`,
        data: {format: 'json'},
        type: 'POST',
        success: function (result) {
            if (result.success) {
                resolve({
                    next: true
                })
            }
        }
    })
})

export default class extends Controller {
    connect() {
        let progress = document.getElementById('progress-bar');
        if (progress !== undefined && progress !== null) {
            save_countries().then(
                result => {
                    if (result.next) {
                        save_keys().then(
                            keys => {
                                if (keys.next) {
                                    save_types().then(
                                        type => {
                                            if (type.next) {
                                                save_api_url().then(
                                                    api_url => {
                                                        if (api_url.next) {
                                                            save_popup().then(
                                                                spopup => {
                                                                    if (spopup.next) {
                                                                        save_modules().then(
                                                                            mod_save => {
                                                                                if (mod_save.next) {

                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }
                                                )
                                            }
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            );


        }


    }

    check_connection() {
        let user = document.getElementById('c2e_user');
        let password = document.getElementById('c2e_user_api_key');
        let seller = document.getElementById('c2e_seller_api_key');
        let error = 0;

        if (user.value.isEmpty()) {
            user.classList.add('is-invalid');
            error++;
        } else {
            user.classList.remove('is-invalid');
            user.classList.add('is-valid');
        }

        if (password.value.isEmpty()) {
            password.classList.add('is-invalid');
            error++;
        } else {
            password.classList.remove('is-invalid');
            password.classList.add('is-valid');
        }

        if (seller.value.isEmpty()) {
            seller.classList.add('is-invalid');
            error++;
        } else {
            seller.classList.remove('is-invalid');
            seller.classList.add('is-valid');
        }

        if (error === 0) {
            $.ajax({
                url: `${location.origin}/es/initial/installation/check_connection`,
                data: {
                    user: user.value,
                    password: password.value,
                    seller: seller.value
                },
                type: 'POST',
                success: function (data) {
                    document.getElementById('msg_connection').innerHTML = `<div class="alert alert-${data.alert_type} alert-dismissible fade show" role="alert">
                                                                                       ${data.msg}
                                                                                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                                                        <span aria-hidden="true">&times;</span>
                                                                                      </button>
                                                                                    </div>`
                    if (data.success) {
                        document.getElementById('btn_submit_connection').removeAttribute('disabled');
                        document.getElementById('btn_submit_connection').style.display = 'inline-block';
                        document.getElementById('btn_next').style.display = 'none';
                    }
                }
            })
        }
    }

    next_step_optional(e){
        bootbox.confirm('¿Desea saltar el paso de configuración de los parametros de conexión al API? <br> Tenga en cuenta que puede despues modificar los mismos en la administracion',function (result) {
            if(result){
                location.href = e.target.dataset.href;
            }
        })
    }
}