import {Controller} from "stimulus"
import $ from 'jquery';

import popper from '@popperjs/core'
import {catalog} from "./catalogs_controller";
import {preferences} from "./preferences_controller";



export default class extends Controller {

    connect() {

        catalog().then(
            catalogo => {
                let html = '';
                if (catalogo.success) {
                    let data = catalogo.data;
                    data.map(product => {

                        let des = product.description
                        des = des.split('<div>');
                        des[0] = '<div>';
                        des[1] = des[1].slice(0,150) + "...";
                        des = des.join(" ");

                         html+= `
   <div class="product">
            <div class="name">${product.category} <p>Desde $${product.price}.00</p></div>
            <div class="image">
              <img src="${product.picture}" alt="${product.category}">
            </div>
            <div class="description">
              <div class="description-info">
                ${des}...
              </div>
              <div class="description-btn">
                <a href="javascript:;"> Ver mas</a>
              </div>
            </div>
          </div>
                        `;
                    })
                }

                $('#_catalog_container').html(html);

            }
        )
    }
}