import {Controller} from "stimulus"
import $ from 'jquery';




export default class extends Controller {

    connect() {
       let last_word = $('.last-word-change');
       let last_word_uppercase = $('.last-word-uppercase-change');
       let last_word_zoom = $('.last-word-zoom');
       let first_word_change = $('.first-word-change');

       last_word.map((index, elem) => {
           if(last_word.hasOwnProperty(index)){
               let text = last_word[index].innerText;
               text = text.trim();
               text = text.split(' ');
               let last = text.pop();
               text = text.join(' ');
               text = text+' '+'<span class="last-word">'+last+'</span>';
               last_word[index].innerHTML = text;
           }
       })

        last_word_uppercase.map((index, elem) => {
            if(last_word_uppercase.hasOwnProperty(index)){
                let text = last_word_uppercase[index].innerText;
                let trim = text.trim();
                let split = trim.split(' ');
                let last = split.pop();
                let join = split.join(' ');
                last_word_uppercase[index].innerHTML = join+' '+'<h1><p class="last-word-upercase">'+last+'</p></h1>';
            }
        })

        last_word_zoom.map((index, elem) => {
            if(last_word_zoom.hasOwnProperty(index)){
                let text = $('#'+last_word_zoom[index].dataset.source).text();
                text = text.trim();
                text = text.split(' ');
                let last = text.pop();
                text = text.join(' ');
                let target = last_word_zoom[index].dataset.target;
                $('#'+target)[0].innerHTML = last.toUpperCase();
                last_word_zoom[index].innerHTML = text;
            }
        })

        first_word_change.map((index, elem) => {
            if(first_word_change.hasOwnProperty(index)){
                let text = $(first_word_change[index])[0].innerText;
                text = text.split(' ');
                let first = text.shift();
                $(first_word_change[index])[0].innerHTML = `<span class="first-word">${first}</span> <span class="other-word">${text.join(' ')}</span>`;
            }
        })
    }
}