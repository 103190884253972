import {Controller} from "stimulus"
import $ from 'jquery';
import {set_locale} from './localize_controller'


export default class extends Controller {

    connect() {
        set_locale().then(
            locale => {
                $.ajax({
                    url: `/${locale.locale}/information/active_notice.json`,
                    data: {
                        format: 'json'
                    },
                    type: 'GET',
                    success: function (notice) {
                        let marquee_area = $('#marquee_state')[0];

                        if (notice.length > 0) {
                            let html = '';
                            notice.map(n => {
                                if (n.slug === "" || n.slug === undefined || n.slug === null) {
                                    html += `
                    <span>
                        <a href="javascript:;">#${n.title}</a> |
                        <span class="description">${n.short_description}</span>
                    </span>
                  `;
                                } else {
                                    html += `
                    <span>
                       <a href="${location.origin + `/${locale.locale}/information/ver/` + n.slug}">#${n.title}</a> |
                        <span class="description">${n.short_description}</span>
                    </span>
                  `;
                                }

                            });

                            marquee_area.innerHTML = html;
                        } else {
                            marquee_area.parentNode.remove();
                        }


                    }
                })
            }
        )
    }

    marquee(event) {
        let target = document.querySelector('#' + event.target.dataset.target);

        if (event.type === 'mouseenter') {
            target.style.animationPlayState = 'paused';
        }
        if (event.type === 'mouseleave') {
            target.style.animationPlayState = 'running';
        }
    }
}